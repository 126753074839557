export default [
  {
    value: 'grocery',
    label: 'Grocery',
    icon: 'LocalGroceryStoreIcon',
    shortName: 'Grocery',
    matches: ['Grocery & Gourmet Food']
  },
  {
    value: 'beauty',
    label: 'Beauty',
    icon: 'GiLipstick',
    shortName: 'Beauty',
    matches: ['Beauty & Personal Care', 'Beauty']
  },
  {
    value: 'health',
    label: 'Health & Household',
    icon: 'LocalHospitalIcon',
    shortName: 'Household',
    matches: ['Health & Household']
  },
  {
    value: 'pet supplies',
    label: 'Pet Supplies',
    icon: 'PetsIcon',
    shortName: 'Pets',
    matches: ['Pet Supplies']
  },
  {
    value: 'toys',
    label: 'Toys & Games',
    icon: 'ToysIcon',
    shortName: 'Toys',
    matches: ['Toys & Games']
  },
  {
    value: 'clothing',
    label: 'Clothing, Shoes & Jewelry',
    skipDatabase: true,
    icon: 'CheckroomIcon',
    shortName: 'Clothing',
    matches: ['Clothing, Shoes & Jewelry']
  },
  {
    value: 'electronics',
    label: 'Electronics',
    icon: 'DevicesIcon',
    shortName: 'Electronics',
    matches: ['Electronics']
  },
  {
    value: 'home',
    label: 'Home & Kitchen',
    icon: 'KitchenIcon',
    shortName: 'Home & Kitchen',
    matches: ['Home & Kitchen']
  },
  {
    value: 'tools',
    label: 'Tools & Home Improvement',
    icon: 'BuildIcon',
    shortName: 'Tools',
    matches: ['Tools & Home Improvement']
  },
  {
    value: 'baby',
    label: 'Baby Products',
    icon: 'ChildCareIcon',
    shortName: 'Baby',
    matches: ['Baby Products', 'Baby']
  },
  {
    value: 'office',
    label: 'Office Products',
    icon: 'WorkIcon',
    shortName: 'Office',
    matches: ['Office Products']
  },
  {
    value: 'books',
    label: 'Books',
    icon: 'MenuBookIcon',
    // skipDatabase: true,
    shortName: 'Books',
    matches: ['Books']
  },
  {
    value: 'sports',
    label: 'Sports & Outdoors',
    icon: 'SportsTennisIcon',
    shortName: 'Sports',
    matches: ['Sports & Outdoors']
  },
  {
    value: 'automotive',
    label: 'Automotive',
    icon: 'DirectionsCarIcon',
    shortName: 'Automotive',
    matches: ['Automotive']
  }
];
